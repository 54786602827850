<template>
    <div class="products-page">
        <base-nav />

        <base-inner-banner img-type="event" />

        <h1 class="text-center page-h1 mb-0">{{ $t('Nav_products')}} </h1>

        <section class="d-flex overflow-hidden mb-4">
            <b-container>
                <b-row>
                    <b-col>
                        <div class="product-wrapper d-flex">
                            <div class="product-image">
                                <img src="@/assets/img/product/airaFace20.png" loading="lazy"
                                    :title="$t('Products.AiraFace20.description')"
                                    :alt="$t('Products.AiraFace20.description')">
                            </div>
                            <div class="product-introduction w-100">
                                <h2 class="wdn-text-hidden">AiraFace 2.0</h2>
                                <div class="airaProduct_logo">
                                    <img loading="lazy" src="@/assets/img/product/airaFace_logo.png"
                                        title="airaMetadata" :alt="$t('Products.AiraFace20.description')">
                                </div>
                                <h3 class="product-description">{{ $t('Products.AiraFace20.description') }}</h3>

                                <h4 class="product-key-features">{{ $t('Products.Review')}}</h4>
                                <div class="d-flex product-info">
                                    <ul class="features mb-0">
                                        <li>{{ $t('Products.AiraFace20.feature_Area') }}
                                            <ul class="features mb-0">
                                                <li>{{ $t('Products.AiraFace20.feature_Area1') }}</li>
                                                <li>{{ $t('Products.AiraFace20.feature_Area2') }}</li>
                                                <li>{{ $t('Products.AiraFace20.feature_Area3') }}</li>
                                            </ul>
                                        </li>
                                        <li>{{ $t('Products.AiraFace20.feature_Operation') }}
                                            <ul class="features mb-0">
                                                <li>{{ $t('Products.AiraFace20.feature_Operation1') }}</li>
                                                <li>{{ $t('Products.AiraFace20.feature_Operation2') }}</li>
                                                <li>{{ $t('Products.AiraFace20.feature_Operation3') }}</li>
                                            </ul>
                                        </li>
                                        <li>{{ $t('Products.AiraFace20.feature_Cost') }}
                                            <ul class="features mb-0">
                                                <li>{{ $t('Products.AiraFace20.feature_Cost1') }}</li>
                                                <li>{{ $t('Products.AiraFace20.feature_Cost2') }}</li>
                                            </ul>
                                        </li>

                                        <li>{{ $t('Products.AiraFace20.feature_Security') }}
                                            <ul class="features mb-0">
                                                <li>{{ $t('Products.AiraFace20.feature_Security1') }}</li>
                                                <li>{{ $t('Products.AiraFace20.feature_Security2') }}</li>
                                                <li>{{ $t('Products.AiraFace20.feature_Security3') }}</li>
                                            </ul>
                                        </li>
                                    </ul>

                                    <div class="d-flex download-file-wrapper">
                                        <img loading="lazy" class="cursor-pointer pdf-icon"
                                            @click="downloadFile('AiraFace20')"
                                            src="@/assets/img/product/pdf-removebg.png"
                                            :title="$t('Products.AiraFace20.fileName')"
                                            :alt="$t('Products.AiraFace20.fileName')">
                                        <div>
                                            <h4 class="cursor-pointer download-title"
                                                @click="downloadFile('AiraFace20')">{{ $t('Products.Download')}}</h4>
                                            <p class="cursor-pointer file-name" @click="downloadFile('AiraFace20')">
                                                {{$t('Products.AiraFace20.fileName')}} </p>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>

                        <div class="product-wrapper d-flex">
                            <div class="product-image">
                                <img src="@/assets/img/product/AiraTrack20.png" loading="lazy"
                                    :title="$t('Products.AiraTrack20.description')"
                                    :alt="$t('Products.AiraTrack20.description')">
                            </div>
                            <div class="product-introduction w-100">
                                <h2 class="wdn-text-hidden">AiraTrack 2.0</h2>
                                <div class="airaProduct_logo">
                                    <img loading="lazy" src="@/assets/img/product/airaTrack_logo.png"
                                        title="airaMetadata" :alt="$t('Products.AiraTrack20.description')">
                                </div>
                                <h3 class="product-description">{{ $t('Products.AiraTrack20.description') }}</h3>

                                <h4 class="product-key-features">{{ $t('Products.Review')}}</h4>
                                <div class="d-flex product-info">
                                    <ul class="features mb-0">
                                        <li>{{ $t('Products.AiraTrack20.feature_Search') }}
                                            <ul class="features mb-0">
                                                <li>{{ $t('Products.AiraTrack20.feature_Search1') }}</li>
                                                <li>{{ $t('Products.AiraTrack20.feature_Search2') }}</li>
                                                <li>{{ $t('Products.AiraTrack20.feature_Search3') }}</li>
                                            </ul>
                                        </li>
                                        <li>{{ $t('Products.AiraTrack20.feature_Track') }}
                                            <ul class="features mb-0">
                                                <li>{{ $t('Products.AiraTrack20.feature_Track1') }}</li>
                                                <li>{{ $t('Products.AiraTrack20.feature_Track2') }}</li>
                                                <li v-show="$t('Products.AiraTrack20.feature_Track3') != ''" >{{ $t('Products.AiraTrack20.feature_Track3') }}</li>
                                            </ul>
                                        </li>
                                        <li>{{ $t('Products.AiraTrack20.feature_Video') }}
                                            <ul class="features mb-0">
                                                <li>{{ $t('Products.AiraTrack20.feature_Video1') }}</li>
                                                <li>{{ $t('Products.AiraTrack20.feature_Video2') }}</li>
                                                <li>{{ $t('Products.AiraTrack20.feature_Video3') }}</li>
                                            </ul>
                                        </li>

                                        <li>{{ $t('Products.AiraTrack20.feature_Cost') }}
                                            <ul class="features mb-0">
                                                <li>{{ $t('Products.AiraTrack20.feature_Cost1') }}</li>
                                                <li>{{ $t('Products.AiraTrack20.feature_Cost2') }}</li>
                                                <li>{{ $t('Products.AiraTrack20.feature_Cost3') }}</li>
                                            </ul>
                                        </li>
                                    </ul>

                                    <div class="d-flex download-file-wrapper">
                                        <img loading="lazy" class="cursor-pointer pdf-icon"
                                            @click="downloadFile('AiraTrack20')"
                                            src="@/assets/img/product/pdf-removebg.png"
                                            :title="$t('Products.AiraTrack20.fileName')"
                                            :alt="$t('Products.AiraTrack20.fileName')">
                                        <div>
                                            <h4 class="cursor-pointer download-title"
                                                @click="downloadFile('AiraTrack20')">{{ $t('Products.Download')}}</h4>
                                            <p class="cursor-pointer file-name" @click="downloadFile('AiraTrack20')">
                                                {{$t('Products.AiraTrack20.fileName')}} </p>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>



                        <!-- <div class="product-wrapper d-flex">
                            <div class="product-image">
                                <img src="@/assets/img/product/airaMetadata.png" loading="lazy"
                                    :title="$t('Products.airaMetadata.description')"
                                    :alt="$t('Products.airaMetadata.description')">
                            </div>
                            <div class="product-introduction w-100">
                                <h2 class="wdn-text-hidden">AiraMetadata</h2>
                                <div class="airaProduct_logo">
                                    <img loading="lazy" src="@/assets/img/product/airaMetadata_logo.png"
                                        title="airaMetadata" :alt="$t('Products.airaMetadata.description')">
                                </div>
                                <h3 class="product-description">{{ $t('Products.airaMetadata.description') }}</h3>

                                <h4 class="product-key-features">{{ $t('Products.Review')}}</h4>
                                <div class="d-flex product-info">
                                    <ul class="features mb-0">
                                        <li>{{ $t('Products.airaMetadata.feature_Attendance') }}</li>
                                        <li>{{ $t('Products.airaMetadata.feature_Welcome') }}</li>
                                        <li>{{ $t('Products.airaMetadata.feature_Video') }}</li>
                                        <li>{{ $t('Products.airaMetadata.feature_Fever') }}</li>
                                        <li>{{ $t('Products.airaMetadata.feature_Compatibility') }}</li>
                                        <li>{{ $t('Products.airaMetadata.feature_Flexible') }}</li>
                                        <li>{{ $t('Products.airaMetadata.feature_Person') }}</li>
                                        <li>{{ $t('Products.airaMetadata.feature_Notification') }}</li>
                                        <li>{{ $t('Products.airaMetadata.feature_Reference') }}</li>
                                    </ul>

                                    <div class="d-flex download-file-wrapper">
                                        <img loading="lazy" class="cursor-pointer pdf-icon"
                                            @click="downloadFile('airaMetadata')"
                                            src="@/assets/img/product/pdf-removebg.png"
                                            :title="$t('Products.airaMetadata.fileName')"
                                            :alt="$t('Products.airaMetadata.fileName')">
                                        <div>
                                            <h4 class="cursor-pointer download-title"
                                                @click="downloadFile('airaMetadata')">{{ $t('Products.Download')}}</h4>
                                            <p class="cursor-pointer file-name" @click="downloadFile('airaMetadata')">
                                                {{$t('Products.airaMetadata.fileName')}} </p>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div> -->



                        <!-- <div class="product-wrapper d-flex">
                            <div class="product-image">
                                <img src="@/assets/img/product/airaFace.png" loading="lazy"
                                    :title="$t('Products.AiraFace.description')"
                                    :alt="$t('Products.AiraFace.description')">
                            </div>
                            <div class="product-introduction w-100">
                                <h2 class="wdn-text-hidden">airaFace</h2>
                                <div class="airaProduct_logo">
                                    <img loading="lazy" src="@/assets/img/product/airaFace_logo.png" title="airaFace"
                                        :alt="$t('Products.AiraFace.description')">
                                </div>
                                <h3 class="product-description">{{ $t('Products.AiraFace.description') }}</h3>

                                <h4 class="product-key-features">{{ $t('Products.Review')}}</h4>
                                <div class="d-flex product-info">
                                    <ul class="features mb-0">
                                        <li>{{ $t('Products.AiraFace.feature_Attendance') }}</li>
                                        <li>{{ $t('Products.AiraFace.feature_Welcome') }}</li>
                                        <li>{{ $t('Products.AiraFace.feature_Video') }}</li>
                                        <li>{{ $t('Products.AiraFace.feature_Fever') }}</li>
                                        <li>{{ $t('Products.AiraFace.feature_Compatibility') }}</li>
                                        <li>{{ $t('Products.AiraFace.feature_Flexible') }}</li>
                                        <li>{{ $t('Products.AiraFace.feature_Person') }}</li>
                                        <li>{{ $t('Products.AiraFace.feature_Notification') }}</li>
                                        <li>{{ $t('Products.AiraFace.feature_Reference') }}</li>
                                    </ul>

                                    <div class="d-flex download-file-wrapper">
                                        <img loading="lazy" class="cursor-pointer pdf-icon"
                                            @click="downloadFile('airaFace')"
                                            src="@/assets/img/product/pdf-removebg.png"
                                            :title="$t('Products.AiraFace.fileName')"
                                            :alt="$t('Products.AiraFace.fileName')">
                                        <div>
                                            <h4 class="cursor-pointer download-title" @click="downloadFile('airaFace')">
                                                {{ $t('Products.Download')}}</h4>
                                            <p class="cursor-pointer file-name" @click="downloadFile('airaFace')">
                                                {{$t('Products.AiraFace.fileName')}} </p>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div> -->

                        <!-- <div class="product-wrapper d-flex">
                            <div class="product-image">
                                <img src="@/assets/img/product/airaTrack.png" loading="lazy"
                                    :title="$t('Products.AiraTrack.description')"
                                    :alt="$t('Products.AiraTrack.description')">
                            </div>
                            <div class="product-introduction w-100">
                                <h2 class="wdn-text-hidden">AiraTrack</h2>
                                <div class="airaProduct_logo">
                                    <img loading="lazy" src="@/assets/img/product/airaTrack_logo.png" title="AiraTrack"
                                        :alt="$t('Products.AiraTrack.description')">
                                </div>
                                <h3 class="product-description">{{ $t('Products.AiraTrack.description') }}</h3>

                                <h4 class="product-key-features">{{ $t('Products.Review')}}</h4>
                                <div class="d-flex product-info">
                                    <ul class="features mb-0">
                                        <h5 class="product-key-features-title">{{
                                            $t('Products.AiraTrack.feature_De_identify.title')}}</h5>
                                        <li>{{ $t('Products.AiraTrack.feature_De_identify.key1') }}</li>
                                        <li>{{ $t('Products.AiraTrack.feature_De_identify.key2') }}</li>
                                        <li>{{ $t('Products.AiraTrack.feature_De_identify.key3') }}</li>
                                        <h5 class="product-key-features-title">{{
                                            $t('Products.AiraTrack.feature_Compare.title')}}</h5>
                                        <li>{{ $t('Products.AiraTrack.feature_Compare.key1') }}</li>
                                        <li>{{ $t('Products.AiraTrack.feature_Compare.key2') }}</li>
                                        <h5 class="product-key-features-title">{{
                                            $t('Products.AiraTrack.feature_Compatibility.title')}}</h5>
                                        <li>{{ $t('Products.AiraTrack.feature_Compatibility.key1') }}</li>
                                        <li>{{ $t('Products.AiraTrack.feature_Compatibility.key2') }}</li>
                                        <h5 class="product-key-features-title">{{
                                            $t('Products.AiraTrack.feature_Search.title')}}</h5>
                                        <li>{{ $t('Products.AiraTrack.feature_Search.key1') }}</li>
                                        <li>{{ $t('Products.AiraTrack.feature_Search.key1') }}</li>
                                    </ul>

                                    <div class="d-flex download-file-wrapper">
                                        <img loading="lazy" class="cursor-pointer pdf-icon"
                                            @click="downloadFile('airaTrack')"
                                            src="@/assets/img/product/pdf-removebg.png"
                                            :title="$t('Products.AiraTrack.fileName')"
                                            :alt="$t('Products.AiraTrack.fileName')">
                                        <div>
                                            <h4 class="cursor-pointer download-title"
                                                @click="downloadFile('airaTrack')">{{ $t('Products.Download')}}</h4>
                                            <p class="cursor-pointer file-name" @click="downloadFile('airaTrack')">
                                                {{$t('Products.AiraTrack.fileName')}} </p>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div> -->



                        <div class="product-wrapper d-flex">
                            <div class="product-image">
                                <img loading="lazy" src="@/assets/img/product/airaTablet plus.png"
                                    :title="$t('Products.AiraTabletPlus.description')"
                                    :alt="$t('Products.AiraTabletPlus.description')">
                            </div>
                            <div class="product-introduction w-100">
                                <h2 class="wdn-text-hidden">airaTablet</h2>
                                <div class="airaProduct_logo">
                                    <img loading="lazy" src="@/assets/img/product/airaTablet plus logo.png"
                                        title="airaFace" :alt="$t('Products.AiraTabletPlus.description')">
                                </div>
                                <h3 class="product-description">{{ $t('Products.AiraTabletPlus.description') }}</h3>

                                <h4 class="product-key-features">{{ $t('Products.Review')}}</h4>
                                <div class="d-flex product-info">
                                    <ul class="features mb-0">
                                        <li>{{ $t('Products.AiraTabletPlus.feature_Quick') }}</li>
                                        <li>{{ $t('Products.AiraTabletPlus.feature_WebBased') }}</li>
                                        <li>{{ $t('Products.AiraTabletPlus.feature_Notifications') }}</li>
                                        <li>{{ $t('Products.AiraTabletPlus.feature_OpenAPI') }}</li>
                                        <li>{{ $t('Products.AiraTabletPlus.feature_MadeInTaiwan') }}</li>
                                        <li>{{ $t('Products.AiraTabletPlus.feature_Recognizes') }}</li>
                                        <li>{{ $t('Products.AiraTabletPlus.feature_Antispoofing') }}</li>
                                    </ul>

                                    <div class="d-flex download-file-wrapper">
                                        <img loading="lazy" class="cursor-pointer pdf-icon"
                                            @click="downloadFile('airaTabletplus')"
                                            src="@/assets/img/product/pdf-removebg.png"
                                            :title="$t('Products.AiraTabletPlus.fileName')"
                                            :alt="$t('Products.AiraTabletPlus.fileName')">
                                        <div>
                                            <h4 class="cursor-pointer download-title"
                                                @click="downloadFile('airaTabletplus')">{{ $t('Products.Download')}}
                                            </h4>
                                            <p class="cursor-pointer file-name" @click="downloadFile('airaTabletplus')">
                                                {{$t('Products.AiraTabletPlus.fileName')}} </p>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>

                        <!-- <div class="product-wrapper d-flex">
                            <div class="product-image">
                                <img loading="lazy" src="@/assets/img/product/airaTablet_mini.png"
                                    :title="$t('Products.AiraTablet_mini.description')"
                                    :alt="$t('Products.AiraTablet_mini.description')">
                            </div>
                            <div class="product-introduction w-100">
                                <h2 class="wdn-text-hidden">airaTablet Mini</h2>
                                <div class="airaProduct_logo">
                                    <img loading="lazy" src="@/assets/img/product/airaTablet_mini_logo.png"
                                        title="airaFace" :alt="$t('Products.AiraTablet_mini.description')">
                                </div>
                                <h3 class="product-description">{{ $t('Products.AiraTablet_mini.description') }}</h3>

                                <h4 class="product-key-features">{{ $t('Products.Review')}}</h4>
                                <div class="d-flex product-info">
                                    <ul class="features mb-0">
                                        <li>{{ $t('Products.AiraTablet_mini.feature_Quick') }}</li>
                                        <li>{{ $t('Products.AiraTablet_mini.feature_WebBased') }}</li>
                                        <li>{{ $t('Products.AiraTablet_mini.feature_Notifications') }}</li>
                                        <li>{{ $t('Products.AiraTablet_mini.feature_OpenAPI') }}</li>
                                        <li>{{ $t('Products.AiraTablet_mini.feature_MadeInTaiwan') }}</li>
                                        <li>{{ $t('Products.AiraTablet_mini.feature_Recognizes') }}</li>
                                        <li>{{ $t('Products.AiraTablet_mini.feature_AntiSpoofing') }}</li>
                                    </ul>

                                    <div class="d-flex download-file-wrapper">
                                        <img loading="lazy" class="cursor-pointer pdf-icon"
                                            @click="downloadFile('airaTablet_mini')"
                                            src="@/assets/img/product/pdf-removebg.png"
                                            :title="$t('Products.AiraTablet_mini.fileName')"
                                            :alt="$t('Products.AiraTablet_mini.fileName')">
                                        <div>
                                            <h4 class="cursor-pointer download-title"
                                                @click="downloadFile('airaTablet_mini')">{{ $t('Products.Download')}}
                                            </h4>
                                            <p class="cursor-pointer file-name"
                                                @click="downloadFile('airaTablet_mini')">
                                                {{$t('Products.AiraTablet_mini.fileName')}} </p>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div> -->
                    </b-col>
                </b-row>
            </b-container>
        </section>

        <!-- <div style="display:block;margin-bottom:90px;text-align:center">
            <a target="_blank" href="https://forms.gle/nwrr8n8KuAGbp7sX6">
                <button class="btn-primary" style="height:52px;width:300px;font-size:32px;border-radius:0.25rem;vertical-align:middle;border:unset;box-sha;box-shadow:0 1px 1px 0 rgb(60 75 100 / 14%), 0 2px 1px -1px rgb(60 75 100 / 12%), 0 1px 3px 0 rgb(60 75 100 / 20%)">聯絡我們</button>
            </a>
        </div> -->

        <base-contact style="background-color: #ffffff;" />

        <base-footer />
    </div>
</template>

<script>
    export default {
        name: 'Products',

        metaInfo() {
            return {
                title: this.$t('Nav_products'),
                meta: [
                    {
                        name: 'description',
                        content: `
                            airaFace - ${this.$t('Products.AiraFace.description')} ,
                            airaTablet plus - ${this.$t('Products.AiraTabletPlus.description')},
                            airaTrack - ${this.$t('Products.AiraTrack.description')},
                            airaTablet - ${this.$t('Products.AiraTablet_mini.description')},
                            airaMetadata - ${this.$t('Products.airaMetadata.description')},
                            airaFace20 - ${this.$t('Products.AiraFace20.description')},
                            airaTrack20 - ${this.$t('Products.AiraTrack20.description')}`
                    }, {
                        name: 'keywords',
                        content: `
                            airaFace - ${this.$t('Products.AiraFace.description')} ,
                            airaTablet plus - ${this.$t('Products.AiraTabletPlus.description')},
                            airaTrack - ${this.$t('Products.AiraTrack.description')},
                            airaTablet - ${this.$t('Products.AiraTablet_mini.description')},
                            airaMetadata - ${this.$t('Products.airaMetadata.description')},
                            airaFace20 - ${this.$t('Products.AiraFace20.description')},
                            airaTrack20 - ${this.$t('Products.AiraTrack20.description')}`
                    }, {
                        name: 'author',
                        content: this.$t('Head_author')
                    }, {
                        name: 'copyright',
                        content: this.$t('Head_copyright')
                    },
                ]
            }
        },

        data() {
            return {}
        },

        computed: {},

        created() {
            window.scrollTo(0, 0);
        },


        methods: {
            downloadFile(productName) {
                const a = document.createElement('a');

                let fileName = "";
                switch (productName) {
                    case 'AiraTrack20': {
                        fileName = this.$t('Products.AiraTrack20.fileName')
                    } break;
                    case 'AiraFace20': {
                        fileName = this.$t('Products.AiraFace20.fileName')
                    } break;
                    case 'airaMetadata': {
                        fileName = this.$t('Products.AiraMetadata.fileName')
                    } break;
                    case 'airaTrack': {
                        fileName = this.$t('Products.AiraTrack.fileName')
                    } break;
                    case 'airaFace': {
                        fileName = this.$t('Products.AiraFace.fileName')
                    } break;
                    case 'airaTablet_mini': {
                        fileName = this.$t('Products.AiraTablet_mini.fileName')
                    } break;
                    case 'airaTablet': {
                        fileName = this.$t('Products.AiraTablet.fileName')
                    } break;
                    case 'airaTabletplus': {
                        fileName = this.$t('Products.AiraTabletPlus.fileName')
                    } break;
                }

                const filePath = `/download_files/${fileName}.pdf`;

                a.href = filePath;
                a.download = fileName;
                a.click()
                a.remove()
            }
        }
    }
</script>

<style lang="scss" scoped>
    .products-page {
        background: #f6f6f6;
    }

    .product-wrapper {
        padding: 40px;
        box-shadow: 0 2px 8px 0 rgba(0, 0, 0, 0.1);
        border: solid 1px #ebebeb;
        background: #fff;
        margin-bottom: 60px;
    }

    .product-image {
        flex: 0 0 400px;
        margin-right: 40px;
        max-width: 400px;
        border: solid 1px #d0d0d0;
        background: #fff;
        max-height: 400px;
        width: 100%;
    }

    .product-image img {
        width: 100%;
        height: 100%;
        object-fit: contain;
        object-position: center;
    }

    .airaProduct_logo {
        height: 60px;
        max-width: 100%;
    }

    .airaProduct_logo img {
        width: auto;
        height: 100%;
    }

    .product-description {
        margin: 15px 0 35px;
        font-size: 18px;
        font-weight: 300;
        line-height: 1.5;
        letter-spacing: normal;
        color: #2c2c2c;
    }

    .product-key-features {
        font-size: 18px;
        margin-bottom: 12px;
        color: #2c2c2c;
    }

    .product-key-features-title {
        margin-top: 10px;
        position: relative;
        left: -19px;
        font-size: 15px;
        font-weight: unset;
    }

    .product-info>* {
        flex: 0 0 50%;
        max-width: 50%;
        padding-left: 20px;
    }

    ul.features {
        border-right: 1px solid #e5e4e4;
    }

    ul.features li {
        padding: 2px 0;
        color: #555;
        font-weight: 300;
    }

    .cursor-pointer {
        cursor: pointer;
    }

    .pdf-icon {
        width: 100%;
        max-width: 88px;
        height: auto;
    }

    .download-file-wrapper {
        align-items: flex-start;
    }

    .download-title {
        font-size: 17px;
        color: #2c2c2c;
        padding-top: 30px;
    }

    .file-name {
        color: #2c2c2c;
        font-size: 17px;
        font-weight: 300;
        transition: color ease .2s;
        word-break: break-all;
        margin-bottom: 0;

        &:hover {
            color: #4290cc;
        }
    }

    @media screen and (max-width: 1024px) {
        .product-image {
            flex: 0 0 250px;
            max-width: 250px;
            max-height: 415px;
        }

        .download-title {
            padding-top: 8px;
        }
    }

    @media screen and (max-width: 812px) {
        .product-wrapper {
            flex-wrap: wrap;
        }

        .product-image {
            margin: 0 auto;
            border: none;
            flex: 0 0 auto;
            max-width: 350px;
        }

        .airaProduct_logo {
            text-align: center;
            margin-top: 20px;
            height: 50px;
        }
    }

    @media screen and (max-width: 576px) {
        .product-info {
            flex-wrap: wrap;
        }

        .product-info>* {
            flex: 0 0 100%;
            max-width: 100%;
        }

        ul.features {
            border: none;
        }

        .download-file-wrapper {
            align-items: center;
            padding-left: 0;
            margin-top: 30px;
        }

        .product-wrapper {
            padding: 20px;
        }
    }
</style>